  *, *::before, *::after {
    box-sizing: border-box;
  }  
  
  html, body, #root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #efefef;
  }
  
  #root {
    width: 100%;
    height: 100vh;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
  } 
  
  .input-phone-number input:focus{
    outline: none !important;
    border:none !important;
    box-shadow: none !important;    
    padding: 3px;    
}

.input-phone-number input {
    outline: none !important;
    border:none !important;
    box-shadow: none !important;
    padding: 3px;
}

.input-phone-number input:disabled {
    color: rgba(0, 0, 0, 0.3);
}

.PhoneInputInput::placeholder {
    color: rgba(0, 0, 0, 0.3);
    opacity: 1; /* Firefox */
  }
  
  ::-ms-input-placeholder { /* Edge 12 -18 */
    color: rgba(0, 0, 0, 0.3);
  }

  noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }

